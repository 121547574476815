module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-148160867-1","head":false,"anonymize":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Dallas Opelt","short_name":"Dallas Opelt","start_url":"/","background_color":"#44475a","theme_color":"#8be9fd","icon":"./src/img/favicon.png","display":"minimal-ui","orientation":"portrait-primary","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"73ad0d03f4e9d8fd89e85c4ce4d1f30c"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
